import React, { useState, useEffect } from "react"
import { Field, FormSpy } from "react-final-form"
import Wizard from "../components/Wizard.js"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import CountryOptions from "../components/countryOptions"
import { Row, Col, Form as form, Alert } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown/with-html"
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
// const stripePromise = loadStripe(process.env.STRIPE_API_KEY)

// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       color: "#344583ff",
//       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//       fontSmoothing: "antialiased",
//       fontSize: "16px",
//       "::placeholder": {
//         color: "#344583ff",
//       },
//     },
//     invalid: {
//       color: "#d91572ff",
//       iconColor: "#d91572ff",
//     },
//   },
// }

export const EnrollmentPageTemplate = ({
  isPreview,
  title,
  steps,
  enroll,
  agreement,
}) => {
  // const stripe = useStripe()
  // const elements = useElements()
  console.log(isPreview)
  const [stripeResponse, setStripeResponse] = useState({
    type: null,
    message: null,
  })
  const [submitEnabled, setSubmitEnabled] = useState(true)
  const [paymentMessage, setPaymentMessage] = useState("5-day") //this will need to update to match "Lightschools with eduScrum (option 1)"
  const [paymentAmount, setPaymentAmount] = useState(13000)
  // useEffect(() => {
  //   if (paymentMessage === "5-day") {
  //     setPaymentAmount(13000)
  //   } else {
  //     setPaymentAmount(8000)
  //   }
  // })
  // Error validation from https://codesandbox.io/s/github/final-form/react-final-form/tree/master/examples/wizard?from-embed=&file=/index.js:1383-1409
  const Error = ({ name }) => (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span className="error">{error}</span> : null
      }
    />
  )
  const required = value => (value ? undefined : "This field is required")

  const agree = value =>
    value ? undefined : "You must agree to the terms in order to continue"

  const Fields = ({
    names,
    subscription,
    fieldsState = {},
    children,
    originalRender,
  }) => {
    if (!names.length) {
      return (originalRender || children)(fieldsState)
    }
    const [name, ...rest] = names
    return (
      <Field name={name} subscription={subscription}>
        {fieldState => (
          <Fields
            names={rest}
            subscription={subscription}
            originalRender={originalRender || children}
            fieldsState={{ ...fieldsState, [name]: fieldState }}
          />
        )}
      </Field>
    )
  }

  // const PaymentMessage = () =>
  //   paymentMessage === "5-day" ? (
  //     <p>
  //       Please enter your card information to pay your 130€ deposit for a 5-day
  //       online Pre-Lightschools Training with eduScrum.
  //     </p>
  //   ) : (
  //     <p>
  //       Please enter your card information to pay your 80€ depsoit for a 3-day
  //       online Pre-Lightschools Training.
  //     </p>
  //   )

  const handleSubmit = vals => {
    setSubmitEnabled(false)

    axios
      .post(`${process.env.SERVER_URL}/mail`, {
        values: vals,
        // paymentIntent: result.paymentIntent,
      })
      .then(res => {
        navigate("/success")
      })
    //get stripe secret from lightschools server
    // axios
    //   .post(`${process.env.SERVER_URL}/secret`, {
    //     values: vals,
    //     paymentAmount: paymentAmount,
    //   })
    //   .then(async res => {
    //     const CLIENT_SECRET = res.data.client_secret
    //     if (!stripe || !elements) {
    //       // Stripe.js has not yet loaded.
    //       return
    //     }

    //     const result = await stripe.confirmCardPayment(CLIENT_SECRET, {
    //       payment_method: {
    //         card: elements.getElement(CardElement),

    //         billing_details: {
    //           email: vals.email,
    //           name: `${vals["first-name"]} ${vals["last-name"]}`,
    //           phone: vals["phone-number"],
    //           address: {
    //             line1: vals["street"],
    //             city: vals.city,
    //             postal_code: vals["postal-code"],
    //             country: vals.country,
    //           },
    //         },
    //       },
    //       setup_future_usage: "off_session",
    //     })
    //     if (result.error) {
    //       // Show error to your customer (e.g., insufficient funds)
    //       setStripeResponse({ type: "danger", message: result.error.message })
    //       setSubmitEnabled(true)
    //     } else {
    //       // The payment has been processed!
    //       if (result.paymentIntent.status === "succeeded") {
    //         setStripeResponse({
    //           type: "success",
    //           message:
    //             "Your payment has been successfully received. Thank you.",
    //         })

    //         axios
    //           .post(`${process.env.SERVER_URL}/mail`, {
    //             values: vals,
    //             paymentIntent: result.paymentIntent,
    //           })
    //           .then(res => {
    //             navigate("/success")
    //           })
    //       }
    //     }
    // })
  }

  return (
    <Layout style={{ backgroundColor: "#b5b8d7ff" }}>
      <SEO title="Pre-Lightschools Training" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center enrollment-section">
            <div style={{ height: "100%" }}>
              <h1 className="sectionTitle">{title}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center enrollment-list">
        <Col xs={10} lg={9}>
          <Wizard
            initialValues={{
              "training-type": "Lightschools with eduScrum (option 1)",
              "training-lightSchool-dates-1": "Jun30",
              "training-lightSchool-dates-2": "Jul21",
              "training-lightSchool-dates-3": "Aug11",
              "training-eduScrum-dates-1": "Jun23",
              "training-eduScrum-dates-2": "Jul14",
              "training-eduScrum-dates-3": "Aug4",
              language: "yes",
            }}
            handleSubmit={handleSubmit}
            // stripe={stripe}
            submitEnabled={submitEnabled}
          >
            {/*


              PAGE 1 - STEPS




            */}
            <Wizard.Page>
              <Row className="d-flex flex-row justify-content-center">
                <Col xs={12} lg={10}>
                  <h5 className="steps text-center mb-5">
                    <span>STEPS</span> > ENROLL > AGREEMENT
                  </h5>
                  <h5 className="my-5 text-center">{steps.heading}</h5>
                  <ReactMarkdown source={steps.content} linkTarget="_blank" />
                  {/* <ol>
                    <li>

                      <p>
                        Please fill out the application form completely. You
                        will be asked to upload a document, so make sure you
                        have it ready.
                      </p>
                      <p>What document?</p>
                      <p className="pl-3">
                        - Your Purpose Letter, Video or Audio with a clear ‘As…
                        I want to become a lightschool teacher or connector/part
                        of Lightschools/… so that…’ <br />
                        There could be more than one ‘As… want… so that….’ in
                        your Purpose Letter, Video or Audio.
                      </p>
                    </li>
                    <li>

                      Please read and accept the{" "}
                      <Link to="/general-terms-and-conditions">
                        Financial Agreement and the General Terms and Conditions
                      </Link>
                    </li>
                    {/* <li>


                      To complete your application you will be asked to submit
                      an online payment for the deposit. We accept all major
                      credit cards. </li>
                    <li>

                      You will receive an automatic email confirming successful
                      online application, when the complete application form
                      with payment is submitted. In case you do not receive this
                      confirmation email, please get in touch with us via email.
                    </li>
                    <li>

                      Within 10 working days after we have received your
                      complete application, you will receive an email from the
                      Lightschools Academy, informing you about the next steps.
                    </li>
                  </ol> */}
                  <h5 className="text-center my-5">
                    <ReactMarkdown source={steps.footer} linkTarget="_blank" />
                  </h5>
                </Col>
                <Col xs={12} lg={8}>
                  <Field
                    name="training-type"
                    component={({ input, ...rest }) => (
                      <form.Group {...rest}>
                        <form.Label>Training Type</form.Label>
                        <form.Control
                          as="select"
                          value={input.value}
                          onChange={input.onChange}
                          name={input.name}
                        >
                          <option value="Lightschools with eduScrum (option 1)">
                            {steps.trainingLabel1}
                          </option>
                          <option value="Lightschools without eduScrum (option 2)">
                            {steps.trainingLabel2}
                          </option>
                        </form.Control>
                      </form.Group>
                    )}
                  ></Field>
                </Col>
              </Row>
            </Wizard.Page>
            {/*


              PAGE 2 - ENROLL




            */}
            <Wizard.Page>
              <h5 className="steps text-center mb-5">
                <span style={{ color: "grey", textDecoration: "none" }}>
                  STEPS >
                </span>{" "}
                <span>ENROLL</span> > AGREEMENT
              </h5>

              <Row className="mt-5">
                {/* TRAINING DATES LIGHTSCHOOLS 3day */}
                <Col xs={12} lg={6}>
                  <p>Lightschool Training Dates</p>
                  <h4 style={{ color: "#344583ff" }}>
                    {enroll.lightschoolDates}
                  </h4>

                  <h5 style={{ color: "#344583ff" }}>
                    {enroll.lightschoolTimes}
                  </h5>
                  {/* <Field
                    name="training-lightSchool-dates-1"
                    component={({ input, ...rest }) => (
                      <>
                        <form.Text className="formText">
                          First Preference
                        </form.Text>
                        <form.Control
                          as="select"
                          value={input.value}
                          onChange={input.onChange}
                          name={input.name}
                          className="mb-2"
                        >
                          <option value="Jun30">
                            Jun 30 - Jul 2 @ 7 AM - 3 PM CEST
                          </option>
                          <option value="Jul21">
                            Jul 21 - Jul 23 @ 9 AM - 5 PM CEST
                          </option>
                          <option value="Aug11">
                            Aug 11 - Aug 13 @ 4 PM - 9 PM CEST
                          </option>
                        </form.Control>
                      </>
                    )}
                  ></Field>
                  <Field
                    name="training-lightSchool-dates-2"
                    component={({ input, ...rest }) => (
                      <>
                        <form.Text className="formText">
                          Second Preference
                        </form.Text>
                        <form.Control
                          as="select"
                          value={input.value}
                          name={input.name}
                          onChange={input.onChange}
                          className="mb-2"
                        >
                          <option value="Jun30">
                            Jun 30 - Jul 2 @ 7 AM - 3 PM CEST
                          </option>
                          <option value="Jul21">
                            Jul 21 - Jul 23 @ 9 AM - 5 PM CEST
                          </option>
                          <option value="Aug11">
                            Aug 11 - Aug 13 @ 4 PM - 9 PM CEST
                          </option>
                        </form.Control>
                      </>
                    )}
                  ></Field>
                  <Field
                    name="training-lightSchool-dates-3"
                    component={({ input, ...rest }) => (
                      <>
                        <form.Text className="formText">
                          Third Preference
                        </form.Text>
                        <form.Control
                          as="select"
                          value={input.value}
                          name={input.name}
                          onChange={input.onChange}
                          className="mb-2"
                        >
                          <option value="Jun30">
                            Jun 30 - Jul 2 @ 7 AM - 3 PM CEST
                          </option>
                          <option value="Jul21">
                            Jul 21 - Jul 23 @ 9 AM - 5 PM CEST
                          </option>
                          <option value="Aug11">
                            Aug 11 - Aug 13 @ 4 PM - 9 PM CEST
                          </option>
                        </form.Control>
                      </>
                    )}
                  ></Field> */}
                </Col>
                <Col xs={12} lg={6}>
                  <p>eduScrum Training Dates</p>
                  <h4 style={{ color: "#344583ff" }}>{enroll.eduscrumDates}</h4>

                  <h5 style={{ color: "#344583ff" }}>{enroll.eduscrumTimes}</h5>
                  {/* <Field
                    name="training-lightSchool-dates-1"
                    component={({ input, ...rest }) => (
                      <>
                        <form.Text className="formText">
                          First Preference
                        </form.Text>
                        <form.Control
                          as="select"
                          value={input.value}
                          onChange={input.onChange}
                          name={input.name}
                          className="mb-2"
                        >
                          <option value="Jun30">
                            Jun 30 - Jul 2 @ 7 AM - 3 PM CEST
                          </option>
                          <option value="Jul21">
                            Jul 21 - Jul 23 @ 9 AM - 5 PM CEST
                          </option>
                          <option value="Aug11">
                            Aug 11 - Aug 13 @ 4 PM - 9 PM CEST
                          </option>
                        </form.Control>
                      </>
                    )}
                  ></Field>
                  <Field
                    name="training-lightSchool-dates-2"
                    component={({ input, ...rest }) => (
                      <>
                        <form.Text className="formText">
                          Second Preference
                        </form.Text>
                        <form.Control
                          as="select"
                          value={input.value}
                          name={input.name}
                          onChange={input.onChange}
                          className="mb-2"
                        >
                          <option value="Jun30">
                            Jun 30 - Jul 2 @ 7 AM - 3 PM CEST
                          </option>
                          <option value="Jul21">
                            Jul 21 - Jul 23 @ 9 AM - 5 PM CEST
                          </option>
                          <option value="Aug11">
                            Aug 11 - Aug 13 @ 4 PM - 9 PM CEST
                          </option>
                        </form.Control>
                      </>
                    )}
                  ></Field>
                  <Field
                    name="training-lightSchool-dates-3"
                    component={({ input, ...rest }) => (
                      <>
                        <form.Text className="formText">
                          Third Preference
                        </form.Text>
                        <form.Control
                          as="select"
                          value={input.value}
                          name={input.name}
                          onChange={input.onChange}
                          className="mb-2"
                        >
                          <option value="Jun30">
                            Jun 30 - Jul 2 @ 7 AM - 3 PM CEST
                          </option>
                          <option value="Jul21">
                            Jul 21 - Jul 23 @ 9 AM - 5 PM CEST
                          </option>
                          <option value="Aug11">
                            Aug 11 - Aug 13 @ 4 PM - 9 PM CEST
                          </option>
                        </form.Control>
                      </>
                    )}
                  ></Field> */}
                </Col>
                {/* TRAINING DATES EDUSCRUM 2day
                THIS GROUP OF FIELDS IS HIDDEN IF TRAINING-TYPE IS EQUAL TO 3-DAY */}
                {/* <Fields names={["training-type"]}>
                  {fieldState =>
                    fieldState["training-type"].input.value === "5-day" ? (
                      <Col xs={12} lg={6}>
                        <p>eduScrum Training Dates</p>
                        <Field
                          name="training-eduScrum-dates-1"
                          component={({ input, ...rest }) => (
                            <>
                              <form.Text className="formText">
                                First Preference
                              </form.Text>
                              <form.Control
                                as="select"
                                value={input.value}
                                onChange={input.onChange}
                                name={input.name}
                                className="mb-2"
                              >
                                <option value="Jun23">
                                  Jun 23 - Jun 25 @ 7 AM - 3 PM CEST
                                </option>
                                <option value="Jul14">
                                  Jul 14 - Jul 15 @ 9 AM - 5 PM CEST
                                </option>
                                <option value="Aug4">
                                  Aug 4 - Aug 5 @ 4 PM - 9 PM CEST
                                </option>
                              </form.Control>
                            </>
                          )}
                        ></Field>
                        <Field
                          name="training-eduScrum-dates-2"
                          component={({ input, ...rest }) => (
                            <>
                              <form.Text className="formText">
                                Second Preference
                              </form.Text>
                              <form.Control
                                as="select"
                                value={input.value}
                                name={input.name}
                                onChange={input.onChange}
                                className="mb-2"
                              >
                                <option value="Jun23">
                                  Jun 23 - Jun 24 @ 7 AM - 3 PM CEST
                                </option>
                                <option value="Jul14">
                                  Jul 14 - Jul 15 @ 9 AM - 5 PM CEST
                                </option>
                                <option value="Aug4">
                                  Aug 4 - Aug 5 @ 4 PM - 9 PM CEST
                                </option>
                              </form.Control>
                            </>
                          )}
                        ></Field>
                        <Field
                          name="training-eduScrum-dates-3"
                          component={({ input, ...rest }) => (
                            <>
                              <form.Text className="formText">
                                Third Preference
                              </form.Text>
                              <form.Control
                                as="select"
                                value={input.value}
                                name={input.name}
                                onChange={input.onChange}
                                className="mb-2"
                              >
                                <option value="Jun23">
                                  Jun 23 - Jun 24 @ 7 AM - 3 PM CEST
                                </option>
                                <option value="Jul14">
                                  Jul 14 - Jul 15 @ 9 AM - 5 PM CEST
                                </option>
                                <option value="Aug4">
                                  Aug 4 - Aug 5 @ 4 PM - 9 PM CEST
                                </option>
                              </form.Control>
                            </>
                          )}
                        ></Field>
                      </Col>
                    ) : (
                      ""
                    )
                  }
                </Fields> */}
              </Row>
              <Row className="mt-5">
                <Col xs={12}>
                  <p>Language</p>
                  <form.Label>
                    The whole module will be taught in English. Do you read and
                    understand English proficiently? If no, please contact us
                    for further discussion.
                  </form.Label>
                </Col>
                <Col xs={12}>
                  <Field
                    name="language"
                    type="radio"
                    value="yes"
                    component={({ input, ...rest }) => (
                      <form.Check
                        inline
                        name={input.name}
                        label="Yes"
                        value="yes"
                        type="radio"
                        checked={input.checked}
                        onChange={input.onChange}
                      />
                    )}
                  ></Field>
                  <Field
                    name="language"
                    type="radio"
                    value="no"
                    component={({ input, meta, ...rest }) => (
                      <form.Check
                        inline
                        name={input.name}
                        label="No"
                        value="no"
                        type="radio"
                        checked={input.checked}
                        onChange={input.onChange}
                      />
                    )}
                  ></Field>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12}>
                  <p>Personal Details</p>
                </Col>
                <Col xs={12} md={6}>
                  <Field
                    name="first-name"
                    type="text"
                    placeholder="First Name"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>First Name</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                        <Error name="first-name" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="last-name"
                    type="text"
                    placeholder="Last Name"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Last Name</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                        <Error name="last-name" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Email Address</form.Label>
                        <form.Control {...input} {...rest} type="email" />
                        <Error name="email" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="street"
                    type="text"
                    placeholder="Street"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Street</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                        <Error name="street" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="postal-code"
                    type="text"
                    placeholder="Postal Code"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Postal Code</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                        <Error name="postal-code" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="city"
                    type="text"
                    placeholder="City"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>City</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                        <Error name="city" />
                      </form.Group>
                    )}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Field
                    name="country"
                    placeholder="Country"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Country</form.Label>
                        <form.Control
                          as="select"
                          value={input.value}
                          name={input.name}
                          onChange={input.onChange}
                          type="text"
                        >
                          <option
                            value=""
                            disabled
                            style={{ color: "rgb(218, 215, 210)" }}
                          >
                            Select a Country
                          </option>
                          <CountryOptions />
                        </form.Control>
                        <Error name="country" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="phone-number"
                    type="text"
                    placeholder="Phone Number"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Phone Number</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="date-of-birth"
                    type="text"
                    placeholder="dd-mm-yyyy"
                    validate={required}
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Date of Birth</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                        <Error name="date-of-birth" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="place-of-birth"
                    type="text"
                    placeholder="Place of Birth"
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Place of Birth</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                      </form.Group>
                    )}
                  />
                  <Field
                    name="country-of-birth"
                    type="text"
                    placeholder="Country of Birth"
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>Country of Birth</form.Label>
                        <form.Control {...input} {...rest} type="text" />
                      </form.Group>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12}>
                  <p>General</p>
                </Col>
                <Col xs={12}>
                  <Field
                    name="general-how-do-you-know"
                    type="text"
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>{enroll.question1}</form.Label>
                        <form.Control {...input} {...rest} as="textarea" />
                      </form.Group>
                    )}
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    name="general-share"
                    type="text"
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>{enroll.question2}</form.Label>
                        <form.Control {...input} {...rest} as="textarea" />
                      </form.Group>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12}>
                  <p>Uploads</p>
                </Col>
                <Col xs={12}>
                  <Field
                    name="upload-link"
                    type="text"
                    component={({ input, ...rest }) => (
                      <form.Group>
                        <form.Label>{enroll.uploadsText}</form.Label>
                        <form.Text className="formText">
                          Please ensure the link is public or unlisted so that
                          we are able to view it.
                        </form.Text>
                        <form.Control
                          {...input}
                          {...rest}
                          type="text"
                          placeholder="Paste a link to your files on Youtube, Google Drive, Dropbox or a similar service"
                        />
                        <Error name="upload-link" />
                      </form.Group>
                    )}
                  />
                </Col>
              </Row>
            </Wizard.Page>

            {/*


              PAGE 3 - AGREEMENT




            */}
            <Wizard.Page>
              <div>
                <h5 className="steps text-center mb-5">
                  <span style={{ color: "grey", textDecoration: "none" }}>
                    STEPS > ENROLL >
                  </span>{" "}
                  <span>AGREEMENT</span>
                </h5>
                <p>
                  <ReactMarkdown source={agreement} linkTarget="_blank" />
                  <div className="mt-3">
                    <Field
                      name="terms-and-conditions"
                      type="checkbox"
                      value="yes"
                      validate={agree}
                      component={({ input, ...rest }) => (
                        <>
                          <form.Check
                            inline
                            name={input.name}
                            label="I have read and understand the Financial Agreement and General Terms and Conditions and accept them."
                            value="accept"
                            type="checkbox"
                            checked={input.checked}
                            onChange={input.onChange}
                            style={{ color: "#344583ff !important" }}
                          />
                          <Error name="terms-and-conditions" />
                        </>
                      )}
                    ></Field>
                  </div>
                </p>
              </div>
            </Wizard.Page>

            {/*


              PAGE 4 - PAYMENT




            */}
            {/* <Wizard.Page>
              <div>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={props => {
                    setPaymentMessage(props.values["training-type"])
                    console.log(props)
                  }}
                ></FormSpy>
                <h5 className="steps text-center mb-5">
                  <span style={{ color: "grey", textDecoration: "none" }}>
                    STEPS > ENROLL > AGREEMENT >
                  </span>{" "}
                  <span>PAYMENT</span>
                </h5>
                <Row className="d-flex justify-content-center">
                  <Col xs={12} md={8} xl={6} className="text-center mb-3 mr-1">
                    <PaymentMessage />
                    <div className="d-flex flex-row justify-content-between flex-wrap flex-sm-nowrap">
                      <div className="card-list card-visa"></div>
                      <div className="card-list card-master"></div>
                      <div className="card-list card-maestro"></div>
                      <div className="card-list card-amex"></div>
                      <div className="card-list card-discover"></div>
                      <div className="card-list card-jcb"></div>
                      <div className="card-list card-diners"></div>
                      <div className="card-list card-union"></div>
                    </div>
                  </Col>
                  <Col xs={12} md={8} xl={6}>
                    <div className="payment-area">
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                    <Alert
                      variant={stripeResponse.type}
                      visible={stripeResponse}
                      className="mt-3 alert"
                    >
                      {stripeResponse.message}
                    </Alert>
                  </Col>
                </Row>
              </div>
            </Wizard.Page> */}
          </Wizard>
        </Col>
      </Row>
    </Layout>
  )
}

const EnrollmentPage = props => {
  const { frontmatter } = props.data.EnrollmentPageTemplate
  console.log(props)
  return (
    // <Elements stripe={stripePromise}>
    <EnrollmentPageTemplate
      title={frontmatter.title}
      steps={frontmatter.steps}
      enroll={frontmatter.enroll}
      agreement={frontmatter.agreement}
    />

    // </Elements>
  )
}

export default EnrollmentPage

export const pageQuery = graphql`
  query($id: String!) {
    EnrollmentPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        steps {
          heading
          content
          footer
          trainingLabel1
          trainingLabel2
        }
        enroll {
          lightschoolDates
          lightschoolTimes
          eduscrumDates
          eduscrumTimes
          question1
          question2
          uploadsText
        }
        agreement
      }
    }
  }
`
